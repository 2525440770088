import React, { useState } from 'react';

import GlobalContext from './GlobalContext';

export type IGlobalProviderProps = {
    children?: React.ReactNode;
};

export const GlobalProvider = ({
    children
}: IGlobalProviderProps): React.ReactElement => {
    const [currPage, setCurrPage] = useState<number>(0);

    const resetCurrPage = () => {
        setCurrPage(0);
    };

    return (
        <GlobalContext.Provider
            value={{
                currPage,
                setCurrPage,
                resetCurrPage
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};

export default GlobalProvider;
