import { GlobalContext } from '../providers/GlobalProvider';
import { useContext } from 'react';

export const useGlobalContext = (): any => {
  const context = useContext(GlobalContext);

  if (!context) {
    throw new Error(
      'Could not find provider in the context. ' +
        'Wrap the root component in a <GlobalProvider>'
    );
  }

  return context;
};

export default useGlobalContext;
