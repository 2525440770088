export default `
<div class="carousel-track" data-glide-el="track">
    <div class="carousel-slides">
        <div data-year="2024">
            <a class="carousel-cta" href="https://vimeo.com/926108362?share=copy" rel="noopener noreferrer" target="_blank">
                <img draggable="false" class="preloaded" src="media/thumbnails/2024_BreakTheSoundBarrier.png" width="326" height="244" alt="" />
                <span class="info-wrap">
                    <span class="info">
                        <strong class="title">Grand Prix<br>Break the Sound Barrier</strong>
                        <span class="subtitle">Syracuse University Newhouse</span>
                        <ul class="credits">
                        <li>Marlana Bianchi</li>
                        <li>Molly Egan</li>
                        </ul>
                    </span>
                </span>
            </a>
        </div>
        <div data-year="2024">
            <a class="carousel-cta" href="https://vimeo.com/936719078" rel="noopener noreferrer" target="_blank">
                <img draggable="false" class="preloaded" src="media/thumbnails/2024_EarSafe.png" width="326" height="244" alt="" />
                <span class="info-wrap">
                    <span class="info">
                        <strong class="title">Winner<br>EarSafe</strong>
                        <span class="subtitle">Miami Ad School Europe</span>
                        <ul class="credits">
                        <li>Ana Gabunia</li>
                        <li>João Parreira</li>
                        </ul>
                    </span>
                </span>
            </a>
        </div>
        <div data-year="2024">
            <a class="carousel-cta" href="https://www.behance.net/gallery/196429647/The-Good-Loop-Spotify" rel="noopener noreferrer" target="_blank">
                <img draggable="false" class="preloaded" src="media/thumbnails/2024_GoodLoop.png" width="326" height="244" alt="" />
                <span class="info-wrap">
                    <span class="info">
                        <strong class="title">Winner<br>The Good Loop</strong>
                        <span class="subtitle">Accademia di Comunicazione</span>
                        <ul class="credits">
                        <li>Andrea Debernardi</li>
                        <li>Emma Ceramelli</li>
                        <li>Enrico Monacelli</li>
                        <li>Giulia Ciapparella</li>
                        <li>Loris Ghidini</li>
                        </ul>
                    </span>
                </span>
            </a>
        </div>
        <div data-year="2024">
            <a class="carousel-cta" href="https://www.youtube.com/watch?v=oNdUUvVT-wM" rel="noopener noreferrer" target="_blank">
                <img draggable="false" class="preloaded" src="media/thumbnails/2024_BeatsInMotion.png" width="326" height="244" alt="" />
                <span class="info-wrap">
                    <span class="info">
                        <strong class="title">Winner<br>Beats in Motion</strong>
                        <span class="subtitle">Leeds Arts University</span>
                        <ul class="credits">
                        <li>Maia Johnston</li>
                        <li>Tate Lampi</li>
                        </ul>
                    </span>
                </span>
            </a>
        </div>
        <div data-year="2023">
            <a class="carousel-cta" href="https://www.behance.net/gallery/168838809/SAFETY-NOISE-VOLVO" rel="noopener noreferrer" target="_blank">
                <img draggable="false" class="preloaded" src="media/thumbnails/2023_Safety_Noise.jpg" width="326" height="244" alt="" />
                <span class="info-wrap">
                    <span class="info">
                        <strong class="title">Grand Prix<br>Safety Noise</strong>
                        <span class="subtitle">Brother Creativity School </br> & Miami Ad School</span>
                        <ul class="credits">
                        <li>Hannia Yaret Rodriguez Torres</li>
                        <li>Victoria Lara Battista</li>
                        <li>Sara Cavallo Bec</li>
                        </ul>
                    </span>
                </span>
            </a>
        </div>

        <div data-year="2023">
            <a class="carousel-cta" href="https://vimeo.com/833374919" rel="noopener noreferrer" target="_blank">
                <img draggable="false" class="preloaded" src="media/thumbnails/2023_Bike_Bell.jpg" width="326" height="244" alt="" />
                <span class="info-wrap">
                    <span class="info">
                        <strong class="title">Grand Prix<br>Bike Bell</strong>
                        <span class="subtitle">Berghs School of Communication</span>
                        <ul class="credits">
                            <li>Albert Bäckman</li>
                            <li>Clara-Fanny Nordström Pagh</li>
                            <li>Emil Sörensen</li>
                            <li>Rebecka Rydgren</li>
                            <li>Sandra Alwan</li>
                        </ul>
                    </span>
                </span>
            </a>
        </div>

        <div data-year="2023">
            <a class="carousel-cta" href="https://www.youtube.com/watch?v=7fu0shhNrcw" rel="noopener noreferrer" target="_blank">
                <img draggable="false" class="preloaded" src="media/thumbnails/2023_Head_lights.jpg" width="326" height="244" alt="" />
                <span class="info-wrap">
                    <span class="info">
                        <strong class="title">Head Lights</strong>
                        <span class="subtitle">Danish School of Media & Journalism</span>
                        <ul class="credits">
                            <li>Sebastian Benn Christensen</li>
                        </ul>
                    </span>
                </span>
            </a>
        </div>

        <div data-year="2023">
            <a class="carousel-cta" href="https://vimeo.com/819965129" rel="noopener noreferrer" target="_blank">
                <img draggable="false" class="preloaded" src="media/thumbnails/2023_Clean_Energy_Hour.jpg" width="326" height="244" alt="" />
                <span class="info-wrap">
                    <span class="info">
                        <strong class="title">Clean Energy Hour</strong>
                        <span class="subtitle">Openlearn Academy, Dongseo University </br> & Hongik University</span>
                        <ul class="credits">
                            <li>Hong MyeongYun</li>
                            <li>Jaeyeon Hwang</li>
                            <li>Woohee Byun</li>
                            <li>Uijin Yang</li>
                        </ul>
                    </span>
                </span>
            </a>
        </div>
        <div data-year="2022">
            <a class="carousel-cta" href="https://vimeo.com/699075293" rel="noopener noreferrer" target="_blank">
                <img draggable="false" class="preloaded" src="media/thumbnails/2022-Ten_Week_Tee-1.jpg" width="326" height="244" alt="" />
                <span class="info-wrap">
                    <span class="info">
                        <strong class="title">Grand Prix<br>Ten Week Tee</strong>
                        <span class="subtitle">Berghs School of Communication, Sweden</span>
                        <ul class="credits">
                        <li>Anna Zetterlund</li>
                        <li>Claes Holm</li>
                        <li>Fabian Luthander</li>
                        <li>Nicolas Lindberg</li>
                        </ul>
                    </span>
                </span>
            </a>
        </div>

        <div data-year="2022">
            <a class="carousel-cta" href="https://vimeo.com/702556503" rel="noopener noreferrer" target="_blank">
                <img draggable="false" class="preloaded" src="media/thumbnails/2022-SharkShare-2.jpg" width="326" height="244" alt="" />
                <span class="info-wrap">
                    <span class="info">
                        <strong class="title">SharkShare</strong>
                        <span class="subtitle">Brother Madrid, Spain</span>
                        <ul class="credits">
                            <li>Álvaro Jiménez Rodríguez</li>
                            <li>Laura Esther Conesa Fernández</li>
                        </ul>
                    </span>
                </span>
            </a>
        </div>

        <div data-year="2022">
            <a class="carousel-cta" href="https://vimeo.com/702457880/82ef8bbd92" rel="noopener noreferrer" target="_blank">
                <img draggable="false" class="preloaded" src="media/thumbnails/2022-The_Tank-3.jpg" width="326" height="244" alt="" />
                <span class="info-wrap">
                    <span class="info">
                        <strong class="title">The Tank</strong>
                        <span class="subtitle">M.AD San Francisco, USA</span>
                        <ul class="credits">
                            <li>Jimmy Featherstone Marcheso</li>
                            <li>Ted Masterson</li>
                            <li>Tony Wong</li>
                        </ul>
                    </span>
                </span>
            </a>
        </div>

        <div data-year="2022">
            <a class="carousel-cta" href="https://vimeo.com/721359613/8ee4c18e03" rel="noopener noreferrer" target="_blank">
                <img draggable="false" class="preloaded" src="media/thumbnails/2022-Sharkwaves-4.jpg" width="326" height="244" alt="" />
                <span class="info-wrap">
                    <span class="info">
                        <strong class="title">SharkWaves</strong>
                        <span class="subtitle">Nangyang Technological University, Singapore</span>
                        <ul class="credits">
                            <li>Athena Tan Jiaxin</li>
                            <li>Keith Samson</li>
                            <li>Seth Low</li>
                        </ul>
                    </span>
                </span>
            </a>
        </div>

        <div data-year="2021">
            <a class="carousel-cta" href="https://lydonong.wixsite.com/portfolio/inventedbykids" rel="noopener noreferrer" target="_blank">
                <img draggable="false" class="preloaded" src="media/thumbnails/2021-invented-by-kids.png" width="326" height="244" alt="" />
                <span class="info-wrap">
                    <span class="info">
                        <strong class="title">Grand Prix<br>Invented by Kids</strong>
                        <span class="subtitle">Nanyang Academy of Fine Arts & Singapore Management University, Singapore</span>
                        <ul class="credits">
                          <li>Faith Low</li>
                          <li>Lydon Ong</li>
                        </ul>
                    </span>
                </span>
            </a>
        </div>
        <div data-year="2021">
            <a class="carousel-cta" href="https://www.youtube.com/watch?v=ksY_3I8taCE" rel="noopener noreferrer" target="_blank">
                <img draggable="false" class="preloaded" src="media/thumbnails/2021-digitalassistance.jpg" width="326" height="244" alt="" />
                <span class="info-wrap">
                    <span class="info">
                        <strong class="title">Digital Assistance</strong>
                        <span class="subtitle">Leeds Arts University, UK</span>
                        <ul class="credits">
                            <li>Hannah Hargreaves</li>
                        </ul>
                    </span>
                </span>
            </a>
        </div>
        <div data-year="2021">
            <a class="carousel-cta" href="https://vimeo.com/530732178" rel="noopener noreferrer" target="_blank">
                <img draggable="false" class="preloaded" src="media/thumbnails/2021-lets-go.png" width="326" height="244" alt="" />
                <span class="info-wrap">
                    <span class="info">
                        <strong class="title">Let’s Go</strong>
                        <span class="subtitle">Brother Barcelona, Spain</span>
                        <ul class="credits">
                            <li>Daniel Rosero </li>
                        </ul>
                    </span>
                </span>
            </a>
        </div>

        <div data-year="2021">
            <a class="carousel-cta" href="https://vimeo.com/530019836" rel="noopener noreferrer" target="_blank">
                <img draggable="false" class="preloaded" src="media/thumbnails/2021-to-do.jpg" width="326" height="244" alt="" />
                <span class="info-wrap">
                    <span class="info">
                        <strong class="title">To Do</strong>
                        <span class="subtitle">Berghs School of Communications, Sweden </span>
                        <ul class="credits">
                            <li>Henrik Billing</li>
                            <li>Henric Gustavsson</li>
                            <li>Hedda Hyland Zackarias Lindskog</li>
                            <li>Lovisa Zeiloth</li>
                        </ul>
                    </span>
                </span>
            </a>
        </div>






















        <div data-year="2019">
            <a class="carousel-cta" href="https://www.youtube.com/watch?v=oOZYJ-7wys8" rel="noopener noreferrer" target="_blank">
                <img draggable="false" class="preloaded" src="media/thumbnails/2019-epilepsy_watch_out.jpg" width="326" height="244" alt="" />
                <span class="info-wrap">
                    <span class="info">
                        <strong class="title">Watch out</strong>
                        <span class="subtitle">Miami Ad School, Hamburg</span>
                        <ul class="credits">
                            <li>Arendse Rohland</li>
                            <li>Sarah Peen Anderson</li>
                        </ul>
                    </span>
                </span>
            </a>
        </div>
        <div data-year="2019">
            <a class="carousel-cta" href="https://vimeo.com/349014189" rel="noopener noreferrer" target="_blank">
                <img draggable="false" class="preloaded" src="media/thumbnails/2019-mcfive.jpg" width="326" height="244" alt="" />
                <span class="info-wrap">
                    <span class="info">
                        <strong class="title">McFive</strong>
                        <span class="subtitle">Hongik University, South Korea</span>
                        <ul class="credits">
                          <li>Lin Heo</li>
                          <li>Dong Hwi Jung</li>
                          <li>Seung Yeon Lee</li>
                          <li>Da Hye Lee</li>
                          <li>Se Hun Oh</li>
                        </ul>
                    </span>
                </span>
            </a>
        </div>
        <div data-year="2019">
            <a class="carousel-cta" href="https://www.youtube.com/watch?v=brLseLGEDCg" rel="noopener noreferrer" target="_blank">
                <img draggable="false" class="preloaded" src="media/thumbnails/2019-urvoice.jpg" width="326" height="244" alt="" />
                <span class="info-wrap">
                    <span class="info">
                        <strong class="title">Ur Voice</strong>
                        <span class="subtitle">University Centre of Fine Arts, Brazil</span>
                        <ul class="credits">
                            <li>Journey Tickets</li>
                            <li>Lucas Y Justino</li>
                            <li>Matheus Velery</li>
                        </ul>
                    </span>
                </span>
            </a>
        </div>


        <div data-year="2018">
            <a class="carousel-cta" href="https://vimeo.com/266137152" rel="noopener noreferrer" target="_blank">
                <img draggable="false" class="preloaded" src="media/thumbnails/2018-hush.jpg" width="326" height="244" alt="" />
                <span class="info-wrap">
                    <span class="info">
                        <strong class="title">Hush for Apple </strong>
                        <span class="subtitle">Miami Ad School Hamburg; Miami Ad School Miami</span>
                        <ul class="credits">
                            <li>Junggle Kim</li>
                            <li>Chaeyeong Seo</li>
                            <li>Cristina Marquez Barreto</li>
                        </ul>
                    </span>
                </span>
            </a>
        </div>
        <div data-year="2018">
            <a class="carousel-cta" href="https://vimeo.com/266154810" rel="noopener noreferrer"  target="_blank">
                <img draggable="false" class="preloaded" src="media/thumbnails/2018-Trash-Scan.jpg" width="326" height="244" alt="" />
                <span class="info-wrap">
                    <span class="info">
                        <strong class="title">TrashScan for Google and Conversation International</strong>
                        <span class="subtitle">Syracuse University, United States</span>
                        <ul class="credits">
                            <li>Emily Alek</li>
                            <li>Jingpo Li</li>
                        </ul>
                    </span>
                </span>
            </a>
        </div>
        <div data-year="2018">
            <a class="carousel-cta" href="https://vimeo.com/265325095" rel="noopener noreferrer"  target="_blank">
                <img draggable="false" class="preloaded" src="media/thumbnails/2018-Truth-Detector.jpg" width="326" height="244" alt="" />
                <span class="info-wrap">
                    <span class="info">
                        <strong class="title">Truth Detector for Facebook</strong>
                        <span class="subtitle">ESPM, Rio de Janeiro; Miami Ad School, Rio de Janeiro</span>
                        <ul class="credits">
                            <li>Felipe Latgé</li>
                            <li>Davi Correia</li>
                            <li>André Pico</li>
                            <li>Bruno Buhr</li>
                        </ul>
                    </span>
                </span>
            </a>
        </div>
        <div data-year="2018">
            <a class="carousel-cta" href="https://vimeo.com/265116617" rel="noopener noreferrer"  target="_blank">
                <img draggable="false" class="preloaded" src="media/thumbnails/2018-ikea-tittut.jpg" width="326" height="244" alt="" />
                <span class="info-wrap">
                    <span class="info">
                        <strong class="title">Tittut for IKEA</strong>
                        <span class="subtitle">School of Visual Arts, United States</span>
                        <ul class="credits">
                            <li>Yerin Kim</li>
                            <li>Woo Jae Yoon</li>
                            <li>Hyei Min Park</li>
                        </ul>
                    </span>
                </span>
            </a>
        </div>
        <div data-year="2018">
            <a class="carousel-cta" href="https://vimeo.com/266159717" rel="noopener noreferrer"  target="_blank">
                <img draggable="false" class="preloaded" src="media/thumbnails/2018-financed-by-the-sun.jpg" width="326" height="244" alt="" />
                <span class="info-wrap">
                    <span class="info">
                        <strong class="title">Financed by the Sun for Nationwide</strong>
                        <span class="subtitle">Miami Ad School, Rio de Janeiro</span>
                        <ul class="credits">
                            <li>Alexandre Oliveira</li>
                            <li> João Muri</li>
                        </ul>
                    </span>
                </span>
            </a>
        </div>
        <div data-year="2017">
                                <a class="carousel-cta" href="https://vimeo.com/181815060"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/2017-BeHeard.png" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                  <span class="info">
                                      <strong class="title">BeHeard for Vocal ID</strong>
                                      <span class="subtitle">Miami Ad School Madrid</span>
                                      <ul class="credits">
                                          <li>Pablo Criado Martínez</li>
                                          <li>Luis Camacho Cabo</li>
                                          <li>Daniel Criado Zilch</li>
                                      </ul>
                                  </span>
                              </span>
                                </a>
                            </div>
                            <div data-year="2017">
                                <a class="carousel-cta" href="https://vimeo.com/213486835"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/2017-AskTheCandidate.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                  <span class="info">
                                      <strong class="title">Ask the Candidates for CNN</strong>
                                      <span class="subtitle">School of Visual Arts (US)</span>
                                      <ul class="credits">
                                          <li>Yeon Sang Yoon</li>
                                          <li>Jin Sug Park</li>
                                      </ul>
                                  </span>
                              </span>
                                </a>
                            </div>
                            <div data-year="2017">
                                <a class="carousel-cta" href=" https://vimeo.com/213880459"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/2017-Bose.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                  <span class="info">
                                      <strong class="title">BoseNeuro 35 for Bose</strong>
                                      <span class="subtitle">VCU Brandcenter (US)</span>
                                      <ul class="credits">
                                          <li>Stanley Hines, Jr</li>
                                          <li>Limah Taeb</li>
                                          <li>Xia Du</li>
                                      </ul>
                                  </span>
                              </span>
                                </a>
                            </div>
                            <div data-year="2017">
                                <a class="carousel-cta" href="https://vimeo.com/213862779"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/2017-4-MediBrush-Kolibree.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                  <span class="info">
                                      <strong class="title">MediBrush for Kolibree</strong>
                                      <span class="subtitle">Miami Ad School Europe</span>
                                      <ul class="credits">
                                          <li>Gabriela van der Linden</li>
                                          <li>Aleksandr Bobrov</li>
                                      </ul>
                                  </span>
                              </span>
                                </a>
                            </div>
                            <div data-year="2017">
                                <a class="carousel-cta" href="https://vimeo.com/212865603"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/2017-Google-Assistant.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                  <span class="info">
                                      <strong class="title">Google Assistant KIDS for Google</strong>
                                      <span class="subtitle">School Of Visual Arts (US)</span>
                                      <ul class="credits">
                                          <li>Min Young Park</li>
                                          <li>Jisoo Hong</li>
                                          <li>Ji Hoon Kim</li>
                                      </ul>
                                  </span>
                              </span>
                                </a>
                            </div>

                            <div data-year="2016">
                                <a class="carousel-cta" href="https://vimeo.com/169606988"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0001_2016 - penguin.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                      <span class="info">
                                          <strong class="title">Book Messenger for Penguin Books</strong>
                                          <span class="subtitle">Kookmin University, Hongik University, <br>Chung-ang University (South Korea)</span>
                                          <ul class="credits">
                                              <li>Jiwon Ko</li>
                                              <li>Miyeon Shin</li>
                                              <li>Seonhaeng Lee</li>
                                              <li>Haeyoon Jhun</li>
                                          </ul>
                                      </span>
                                  </span>
                                </a>
                            </div>
                            <div data-year="2016">
                                <a class="carousel-cta" href="https://vimeo.com/162742143"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0000_2016 - Amazon Emma.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                      <span class="info">
                                          <strong class="title">Amazon Emma for Amazon</strong>
                                          <span class="subtitle">VCU Brandcenter (United States)</span>
                                          <ul class="credits">
                                              <li>Yanci Wu and Xia Du</li>
                                          </ul>
                                      </span>
                                  </span>
                                </a>
                            </div>
                            <div data-year="2016">
                                <a class="carousel-cta" href="https://vimeo.com/162557728"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0001_2016 - spotify.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                      <span class="info">
                                          <strong class="title">Music Speaks for Spotify</strong>
                                          <span class="subtitle">Berghs School of Communication (Sweden)</span>
                                          <ul class="credits">
                                              <li>Sebastian Brännén</li>
                                              <li>Maria Lashari</li>
                                          </ul>
                                      </span>
                                  </span>
                                </a>
                            </div>
                            <div data-year="2016">
                                <a class="carousel-cta" href="https://vimeo.com/162652581"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0001_2016 - IKEA.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                      <span class="info">
                                          <strong class="title">IKEA HÅLLBAR for IKEA</strong>
                                          <span class="subtitle">Berghs School of Communication (Sweden)</span>
                                          <ul class="credits">
                                              <li>Filip Myringer</li>
                                              <li>Amelie Farmer</li>
                                              <li>Olga Lillienau</li>
                                              <li>Sofia Bleiweiss</li>
                                              <li>Ebba von Zweigbergk</li>
                                          </ul>
                                      </span>
                                  </span>
                                </a>
                            </div>
                            <div data-year="2016">
                                <a class="carousel-cta" href="https://vimeo.com/158462058"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0001_2016 - LEGO.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                      <span class="info">
                                          <strong class="title">Lego Generator for Lego</strong>
                                          <span class="subtitle">Beckmans College of Design (Sweden)</span>
                                          <ul class="credits">
                                              <li>Petter Monsen</li>
                                              <li>Tomas Möller</li>
                                              <li>Axel Månson Lokrantz</li>
                                              <li>Kristofer Gullard Lindgren</li>
                                              <li>Simon Kraft</li>
                                          </ul>
                                      </span>
                                  </span>
                                </a>
                            </div>
                            <div data-year="2015">
                                <a class="carousel-cta" href="https://vimeo.com/125654345"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0037_2015 - chromebook_type.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">chromebook_type</strong>
                                        <span class="subtitle">Miami Ad School, <br>San Francisco and Europe</span>
                                        <ul class="credits">
                                            <li>Elton Rhee</li>
                                            <li>Ludvig Pehrson</li>
                                            <li>Louis Meyer</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2015">
                                <a class="carousel-cta" href="https://vimeo.com/130300395"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0040_2015 - snaphelp.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Snaphelp</strong>
                                        <span class="subtitle">KyungHee University, <br>Bigant Academy</span>
                                        <ul class="credits">
                                            <li>Yusol Shim</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2015">
                                <a class="carousel-cta" href="https://vimeo.com/125247473"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0039_2015 - uber_first_aid.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">UberFIRST-AID</strong>
                                        <span class="subtitle">Fondazione Accademia <br>di Comunicazione</span>
                                        <ul class="credits">
                                            <li>Andrea Raia</li>
                                            <li>Andrea Zanino</li>
                                            <li>Pierpaolo Bivio</li>
                                            <li>Francesco Sguinzi</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2015">
                                <a class="carousel-cta" href="https://vimeo.com/125619482"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0038_2015 - safe-stamp.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">SafeStamp</strong>
                                        <span class="subtitle">Miami Ad School San Francisco</span>
                                        <ul class="credits">
                                            <li>Divya Seshadri</li>
                                            <li>Meghan D. O’Neill</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2015">
                                <a class="carousel-cta" href="https://vimeo.com/cherylseah/treeprintwwf"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0041_2015 - treeprint.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Treeprint</strong>
                                        <span class="subtitle">LASALLE College of the Arts</span>
                                        <ul class="credits">
                                            <li>Cheryl Seah Su Yin</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2014">
                                <a class="carousel-cta" href="http://www.vimeo.com/92444372"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0003_2014 - Donate By Update.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Donate By Update</strong>
                                        <span class="subtitle">Willem de Kooning <br />Academy Rotterdam</span>
                                        <ul class="credits">
                                            <li>Tim Blaney Davidson</li>
                                            <li>Bastian Lievers</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2014">
                                <a class="carousel-cta" href="http://www.vimeo.com/92441595"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0002_2014 - Heart Me.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">HEARt Me</strong>
                                        <span class="subtitle">Miami Ad School <br>Europe</span>
                                        <ul class="credits">
                                            <li>Anne Walde</li>
                                            <li>Nicole Schurz</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2014">
                                <a class="carousel-cta" href="http://www.vimeo.com/89463659"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0001_2014 - Zero Climate.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Do Zero For<br /> Climate Change</strong>
                                        <span class="subtitle">Berghs School of <br>Communication</span>
                                        <ul class="credits">
                                            <li>Fabian Lakander</li>
                                            <li>Pia Hansson Naslund</li>
                                            <li>Afshin Piran</li>
                                            <li>Sebastian Sandberg</li>
                                            <li>Linda Kraft</li>
                                        </ul>
                                    </span>
                                </span></a>
                            </div>
                            <div data-year="2014">
                                <a class="carousel-cta" href="http://www.vimeo.com/97528184"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0000_2014 - Google Gesture.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Google Gesture</strong>
                                        <span class="subtitle">Berghs School of <br>Communication</span>
                                        <ul class="credits">
                                            <li>David Svedenström</li>
                                            <li>Ludwig Hallstensson</li>
                                            <li>August Östberg</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2014">
                                <a class="carousel-cta" href="https://vimeo.com/92664016"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0004_2014 - Passion Is Power.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Passion is Power</strong>
                                        <span class="subtitle">School of <br>Communication Arts 2.0</span>
                                        <ul class="credits">
                                            <li>Adam Radi</li>
                                            <li>Mathias Trads</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2013">
                                <a class="carousel-cta" href="http://vimeo.com/57295627"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0008_2013 - Accel IBM.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Project Accel</strong>
                                        <span class="subtitle">The Creative Circus</span>
                                        <ul class="credits">
                                            <li>Jarrett Jamison</li>
                                            <li>Verenice Lopez</li>
                                            <li>Bradley Daniely</li>
                                            <li>Nath Cochran</li>
                                            <li>Devin McGillivary</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2013">
                                <a class="carousel-cta" href="http://vimeo.com/63472827"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0007_2013 - Editorialist.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Editorialist</strong>
                                        <span class="subtitle">Miami Ad School <br>San Francisco</span>
                                        <ul class="credits">
                                            <li>Sara Uhelski</li>
                                            <li>Alexander Norling</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2013">
                                <a class="carousel-cta" href="http://vimeo.com/61489689"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0006_2013 - Keep it.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Keepit</strong>
                                        <span class="subtitle">Miami Ad School <br>Europe</span>
                                        <ul class="credits">
                                            <li>Caroline Escobar</li>
                                            <li>Alejandro Ladeveze</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2013">
                                <a class="carousel-cta" href="http://vimeo.com/64833627"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0005_2013 - Amazon Awaken.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Awaken by Amazon</strong>
                                        <span class="subtitle">Keio University</span>
                                        <ul class="credits">
                                            <li>Tatsuki Tatara</li>
                                            <li>Konomi Tashiro</li>
                                            <li>Tomoki Hayashida</li>
                                            <li>Taichi Nihei</li>
                                            <li>Kenji Shimo</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2013">
                                <a class="carousel-cta" href="http://www.vimeo.com/64860956"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0004_2013 - Pebble Danger.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Pebble: Sense Danger</strong>
                                        <span class="subtitle">School of <br>Communication Arts 2.0</span>
                                        <ul class="credits">
                                            <li>Thomas Bender</li>
                                            <li>Thomas Corcoran</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2012">
                                <a class="carousel-cta" href="http://vimeo.com/36166880"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0013_2012 - Visit Japan.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Post from Japan</strong>
                                        <span class="subtitle">Berghs School of <br>Communications</span>
                                        <ul class="credits">
                                            <li>Kristofer Salsborn</li>
                                            <li>Rickard Beskow</li>
                                            <li>Michal Sitkiewicz</li>
                                            <li>Eva Wallmark</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2012">
                                <a class="carousel-cta" href="http://vimeo.com/36174030"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0012_2012 - Bing Automatic.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Bing Automatic</strong>
                                        <span class="subtitle">VCU Brandcenter</span>
                                        <ul class="credits">
                                            <li>Christopher Sheldon</li>
                                            <li>Marybeth Ledesma</li>
                                            <li>Nadia Hyder</li>
                                            <li>Nick Maschmeyer</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2012">
                                <a class="carousel-cta" href="http://vimeo.com/40366376"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0011_2012 - Blackout.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Blackout</strong>
                                        <span class="subtitle">Miami Ad School <br>Berlin</span>
                                        <ul class="credits">
                                            <li>Yvonne Truun</li>
                                            <li>Florian Weitzel</li>
                                            <li>Jonas Martin</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2012">
                                <a class="carousel-cta" href="https://www.youtube.com/watch?v=mDAxor2yG4g"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0010_2012 - Penguin.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Penguin Soundtracks</strong>
                                        <span class="subtitle">Miami Ad School <br>Hamburg</span>
                                        <ul class="credits">
                                            <li>Lisa Zeitlhuber</li>
                                            <li>Nicholas Partyka</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2012">
                                <a class="carousel-cta" href="http://juniorcreative.se/madebywaves/"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0009_2012 - Made by waves.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Made by waves</strong>
                                        <span class="subtitle">Berghs School of <br>Communications</span>
                                        <ul class="credits">
                                            <li>Patrik Beskow</li>
                                            <li>Daniel Lunde</li>
                                            <li>David Alledal</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2011">
                                <a class="carousel-cta" href="http://vimeo.com/19101627"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0017_2011 - Balls of pride.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Balls of Pride</strong>
                                        <span class="subtitle">Miami Ad School <br>New York</span>
                                        <ul class="credits">
                                            <li>Matilda Kahl</li>
                                            <li>Jacob Sempler</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2011">
                                <a class="carousel-cta" href="http://vimeo.com/23386015"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0016_2011 - WWF Powernap.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">WWF – Powernap</strong>
                                        <span class="subtitle">Beckmans College of Design</span>
                                        <ul class="credits">
                                            <li>Per Sturesson</li>
                                            <li>Eddie Åhgren</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2011">
                                <a class="carousel-cta" href="http://vimeo.com/24806783"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0015_2011 - Flowers by fb.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Flowers from Facebook</strong>
                                        <span class="subtitle">Miami Ad School <br>Hamburg</span>
                                        <ul class="credits">
                                            <li>Katharina Schmitt</li>
                                            <li>Yasmina Boustani</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2011">
                                <a class="carousel-cta" href="http://vimeo.com/23367146"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0014_2011 - Netflix places.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Netflix</strong>
                                        <span class="subtitle">Miami Ad School São Paulo</span>
                                        <ul class="credits">
                                            <li>Gabriel T. Garcia</li>
                                            <li>Felipe Sampaio</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2010">
                                <a class="carousel-cta" href="http://vimeo.com/11535768"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0022_2010 - change.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Change</strong>
                                        <span class="subtitle">Miami Ad School</span>
                                        <ul class="credits">
                                            <li>Thomas Ilum</li>
                                            <li>Vera Karpova</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2010">
                                <a class="carousel-cta" href="http://vimeo.com/17857857"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0021_2010 - absolut.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Absolut</strong>
                                        <span class="subtitle">Berghs School of <br>Communication</span>
                                        <ul class="credits">
                                            <li>Johan Gerdin</li>
                                            <li>Olle Isaksson</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2010">
                                <a class="carousel-cta" href="http://vimeo.com/11549548"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0020_2010 - Bring Change.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Bring Change</strong>
                                        <span class="subtitle">Berghs School of <br>Communication</span>
                                        <ul class="credits">
                                            <li>Tobias Fant</li>
                                            <li>Jonas Åhlén</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2010">
                                <a class="carousel-cta" href="http://vimeo.com/11510472"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0019_2010 - Coop.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Re-coop</strong>
                                        <span class="subtitle">Berghs School of <br>Communication</span>
                                        <ul class="credits">
                                            <li>Arvid Ringborg</li>
                                            <li>Felipe Montt</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2010">
                                <a class="carousel-cta" href="http://vimeo.com/11668056"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0018_2010 - Penguin tale.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">A Penguin's Tale</strong>
                                        <span class="subtitle">Danish School of Media</span>
                                        <ul class="credits">
                                            <li>Peter Ammentorp</li>
                                            <li>Nicolai Villads</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2009">
                                <a class="carousel-cta" href="http://www.youtube.com/watch?v=S3hS6BQDgwg1"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0026_2009 - Fortum activ.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Fortum Active</strong>
                                        <span class="subtitle">Berghs School of <br>Communication</span>
                                        <ul class="credits">
                                            <li>Gustav Johansson</li>
                                            <li>Erik Högfeldt</li>
                                            <li>Rasmus Keger</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2009">
                                <a class="carousel-cta" href="http://jasondeacon.com/future-lions/watch/"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0025_2009 - Expedia warm up.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Expedia</strong>
                                        <span class="subtitle">Royal Melbourne Institute</span>
                                        <ul class="credits">
                                            <li>Ebony Holmberg</li>
                                            <li>Jason Deacon</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2009">
                                <a class="carousel-cta" href="media/pdf/IKEA_2009.pdf"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0024_2009 - Ikea.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Ikea</strong>
                                        <span class="subtitle">Nanyang Academy of <br>Fine Arts</span>
                                        <ul class="credits">
                                            <li>Gian Jonathan</li>
                                            <li>Wai Wa</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2009">
                                <a class="carousel-cta" href="media/pdf/Starbucks_BeSteady_2009.pdf"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0023_2009 - starbucks.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Starbucks BeSteady</strong>
                                        <span class="subtitle">School of Visual Arts, NY</span>
                                        <ul class="credits">
                                            <li>Sungkwon Ha</li>
                                            <li>Michael Jungho Oh</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2008">
                                <a class="carousel-cta" href="https://www.facebook.com/video/video.php?v=84638037351"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0031_2008 - Maglite.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Maglite</strong>
                                        <span class="subtitle">Berghs School of <br>Communication</span>
                                        <ul class="credits">
                                            <li>Tomas Jonson</li>
                                            <li>Carl Fredrick Jennerfeldt</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2008">
                                <a class="carousel-cta" href="media/pdf/Boble_e_Trek_Tracker_2008.pdf"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0030_20087 - Boble-e.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Boble-e Trek Tracker</strong>
                                        <span class="subtitle">VCU Adcenter</span>
                                        <ul class="credits">
                                            <li>J.D. Humphreys</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2008">
                                <a class="carousel-cta" href="media/pdf/Sharpie_2008.pdf"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0029_2008 - Vote for change.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Sharpie</strong>
                                        <span class="subtitle">VCU Adcenter</span>
                                        <ul class="credits">
                                            <li>Jeremy Claud</li>
                                            <li>Mollie Patesotti</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2008">
                                <a class="carousel-cta" href="https://www.facebook.com/video/video.php?v=51929717351"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0028_2008 - Starbucks.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Starbucks Signature</strong>
                                        <span class="subtitle">The Creative Circus</span>
                                        <ul class="credits">
                                            <li>Karen Gereffi</li>
                                            <li>Katy Graham</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2008">
                                <a class="carousel-cta" href="media/pdf/Toyota_Prius_2008.pdf"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0027_2008 - Toyota prius.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Toyota Prius</strong>
                                        <span class="subtitle">The Creative Circus</span>
                                        <ul class="credits">
                                            <li>Andy Pearson</li>
                                            <li>Liza Behles</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2007">
                                <a class="carousel-cta" href="media/pdf/Aquarius_FL2007.pdf"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0036_2007 - Aquarius.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Aquarius</strong>
                                        <span class="subtitle">Hogeschool Sint-Lukas <br>Brussel</span>
                                        <ul class="credits">
                                            <li>Menno Buyl</li>
                                            <li>Stijn Jansen</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2007">
                                <a class="carousel-cta" href="http://vimeo.com/21106726"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0035_2007 - Graffiti.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Digital graffiti billboards</strong>
                                        <span class="subtitle">Design Factory International</span>
                                        <ul class="credits">
                                            <li>Benjamin Busse</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2007">
                                <a class="carousel-cta" href="media/pdf/1800Flowers_FL2007.pdf"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0034_2007 - 1800flowers.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">1800 Flowers</strong>
                                        <span class="subtitle">The Creative Circus</span>
                                        <ul class="credits">
                                            <li>Gabriel Cheung</li>
                                            <li>Adam Schaffer</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2007">
                                <a class="carousel-cta" href="media/pdf/GreenPeace_FL2007.pdf"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0033_2007 - Green peace.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">GreenPeace</strong>
                                        <span class="subtitle">The Creative Circus</span>
                                        <ul class="credits">
                                            <li>Todd Rone Parker</li>
                                            <li>Peter Trueblood</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
                            <div data-year="2007">
                                <a class="carousel-cta" href="media/pdf/PresentChildAbuse_FL2007.pdf"  rel="noopener noreferrer" target="_blank">
                                    <img draggable="false" class="preloaded" src="media/thumbnails/Desktop_thumbnails_0032_2007 - Child abuse.jpg" width="326" height="244" alt="" />
                                    <span class="info-wrap">
                                    <span class="info">
                                        <strong class="title">Child Abuse</strong>
                                        <span class="subtitle">Miami Ad School</span>
                                        <ul class="credits">
                                            <li>Eric Deichl</li>
                                            <li>Dave Brown</li>
                                        </ul>
                                    </span>
                                </span>
                                </a>
                            </div>
    </div>
</div>
`;
