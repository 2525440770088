import { createContext } from 'react';

export interface IGlobalContextProps {
    currPage: number;
    setCurrPage: (value: number) => void;
    resetCurrPage: () => void;
}

const GlobalContext = createContext<null | IGlobalContextProps>(null);

export default GlobalContext;
