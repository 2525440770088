import React from 'react';
import styles from './App.module.scss';
import { TermsAndConditions } from './components/terms-and-conditions/TermsAndConditions';
import { PrivacyPolicy } from './components/privacy/PrivacyPolicy';
import {
    HashRouter as Router,
    Redirect,
    Route,
    Switch
} from 'react-router-dom';
import { Brief } from './components/brief/Brief';
import { GlobalProvider } from './providers/GlobalProvider';
export class App extends React.Component<any, any> {
    render() {
        return (
            <GlobalProvider>
                <div className={styles.app}>
                    <Router>
                        <Switch>
                            <Route exact path='/' component={Brief} />
                            <Route
                                path='/terms-and-conditions'
                                component={TermsAndConditions}
                            />
                            <Route
                                path='/privacy-policy'
                                component={PrivacyPolicy}
                            />
                            <Route
                                path='*'
                                component={() => <Redirect to={'/'} />}
                            />
                        </Switch>
                    </Router>
                </div>
            </GlobalProvider>
        );
    }
}

export default App;
