import {RefObject} from "react";

interface calcHeroAnimationProps {
    scrollPos: number,
    heroImageRef: RefObject<HTMLImageElement>,
    heroAnimStart: number,
    heroAnimFinish: number,
    isReverse?: boolean
}

const calcHeroAnimation = ({scrollPos, heroImageRef, heroAnimStart, heroAnimFinish, isReverse=false}: calcHeroAnimationProps) => {
    const animationHeight = 28*3;
    const fadeOut = 5;
    let xPos = 0;
    let reverseXPos = 0;
    let animationCss = {};
    let opacity = 1;
    const largeScreen = window.innerWidth >= 900;

    if (scrollPos > heroAnimStart && scrollPos < heroAnimFinish && heroImageRef) {
        // Map scroll position to x position so that hero image scrolls horizontally from left to right
        // xPos is 0 when position is heroAnimStart and
        // xPos is (heroImageRef.current.clientWidth - window.innerWidth) when position is heroAnimFinish
        let interpolated = (scrollPos-heroAnimStart) / (heroAnimFinish-heroAnimStart);
        let transition = -~~((interpolated * 2 - 1) * animationHeight);
        const delta = transition + (animationHeight - fadeOut);
        let containerWidth = window.innerWidth;
        let padding = 0;

        if (interpolated > 1) interpolated = 1;

        // Reverse parallax on Alumni page
        if (isReverse) {
            // Cater for extra padding around hero wrapper on large screens
            padding = largeScreen ? -60 : 20;

            if (heroImageRef.current.closest('.hero-wrapper') !== null) {
                // Slow down horizontal scroll for large screens
                interpolated = largeScreen ? interpolated * 0.6 : interpolated;
                containerWidth = heroImageRef.current.closest('.hero-wrapper').clientWidth;
            }
        }

        xPos = (heroImageRef.current.clientWidth - containerWidth) * interpolated;
        if (isReverse) {
            // Calculate xPos for reverse scroll from right to left
            reverseXPos = (heroImageRef.current.clientWidth-(xPos+containerWidth)) + padding;
        }
        // console.log(heroAnimStart, containerWidth, reverseXPos, padding);
        // console.log(scrollPos, interpolated, heroImageRef.current.clientWidth, window.innerWidth, heroImageRef.current.clientWidth - window.innerWidth, xPos);

        // Calculate opacity for fadeOut for home page only
        if (heroAnimStart === 0) {
            opacity = delta < 0 ? (fadeOut - Math.abs(delta)) / fadeOut : 1;
        }
    }

    animationCss = {
        transform: `translate3d(-${isReverse ? reverseXPos : xPos}px, 0, 0)`,
        opacity: opacity
    };
    return animationCss;
};

export default calcHeroAnimation;