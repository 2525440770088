import React, { useState, useEffect } from 'react';
import s from './Brief.module.scss';
import cn from 'classnames';
import Video from './../video/Video';
import VideoPosterImageAwards from '../../assets/2024/video_cover/award_show_cover.png';


interface AwardsPropsType {
    inView: boolean
}

const Awards = ({ inView }: AwardsPropsType) => {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);
    const [videoPlaying, setVideoPlaying] = useState(false);
    return (
        <div className={s.pageCentered}>
            <div className={cn(s.pageFullWidth, s.pageBrief, s.pageAwards)}>
                <div className={s.briefContainer}>
                    <div className={s.contentWrapper}>
                        <div className={s.briefHeader}>
                            <div className={s.briefHeaderText}>AWARD CEREMONY</div>
                        </div>
                        <div className={s.briefDescription}>
                            <p>
                            </p>
                        </div>

                    </div>
                    <div className={s.heroWrapper}>
                        <Video src='https://vimeo.com/986441978?share=copy' setVideoPlaying={setVideoPlaying} poster={VideoPosterImageAwards} />
                    </div>
                    <div className={s.awardsDescription}>
                        <p>The 2024 Future Lions prize giving took place on the main stage at Spotify Beach as part of a special session focussed on the creative power <span className={s.nowrap}>of Gen Z.</span></p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Awards;
