import React, {cloneElement, ReactHTMLElement} from 'react';
import cn from 'classnames';
import {Tabs} from '../tabs/Tabs';
import panels from './panels';
import s from "../brief/Brief.module.scss";

type FaqProps =  { inView: boolean } & ReactHTMLElement<HTMLDivElement>['props'];

export class Faq extends React.Component<FaqProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            openTabId: 0
        };
    }

    titleToPanelMap = {
        ENTRIES: 0,
        EXECUTION: 1,
        FORMAT: 2,
        ELIGIBILITY: 3
    };
    tabsDef = [
        {label: 'ENTRIES'},
        {label: 'EXECUTION'},
        {label: 'FORMAT'},
        {label: 'ELIGIBILITY'}
    ];
    tabClick = (tab) => {
        this.switchToPanel(tab.label);
    };

    switchToPanel(label: string) {
        this.setState({
            openTabId: this.titleToPanelMap[label]
        });
    }

    render() {
        const found = panels.find((p, idx) => idx === this.state.openTabId);

        return (
            <div className={s.pageCentered}>
                <div className={cn(s.pageFullWidth, s.pageFaq)}>
                <div className={s.faqContainer}>
                    <div className='faq faq-wrapper'>
                        <div className='faq-header'>

                            <div className='faq-header-text'>FAQ</div>
                        </div>

                        <div className='faq-nav'>
                            <Tabs tabsDef={this.tabsDef} onClick={this.tabClick}
                                  isDisabled={() => false}/>
                        </div>
                        <div className='faq-panels'>
                            {found &&
                            cloneElement(found, {
                                key: this.state.openTabId,
                                className: cn('faq-panel', {
                                    active: true
                                })
                            })}
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}
