import React from 'react';
import s from './Brief.module.scss';
import cn from 'classnames';

interface SchoolOfYearPropTypes {
    inView: boolean
}

const SchoolOfTheYear = ({inView}:SchoolOfYearPropTypes) => {

    return (
      <div className={s.pageCentered}>
        <div className={cn(s.pageFullWidth, s.pageSchoolOfYear)}>
            <div className={s.schoolOfYearContainer}>
                <div className={s.descriptionWrapper}>
                    <div className={s.header}>
                        <div className={s.headerText}>TOM BEDECARRÉ SCHOOL OF THE YEAR AWARD</div>
                    </div>

                    <div className={s.descriptionText}>
                        {/* <p className={s.descriptionTitle}>
                        Tom Bedecarré School of the Year Award
                        </p> */}
                        <div className={s.descriptionContent}>
                            <p>
                                The Tom Bedecarré School of the Year Award is in honour of AKQA's <span className={s.nowrap}>Chairman Emeritus.</span>
                            </p>
                            <p>
                                The award recognises schools, academies and educational centres that demonstrate a proven and pioneering approach, broadening access and leveraging technology to <span className={s.nowrap}>amplify impact.</span>
                            </p>

                            <p>
                                The winner of the inaugural 2024 Tom Bedecarré School of the Year Award is OpenLearn Academy in <span className={s.nowrap}>South Korea.</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    );
};

export default SchoolOfTheYear;
