import React, { ReactHTMLElement } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

type CookieBannerProps = {
} & ReactHTMLElement<HTMLDivElement>['props'];

export class CookieBanner extends React.Component<CookieBannerProps, any> {
    state = {collapsed: false, immediate: false}
    componentDidMount()
    {
        const accepted = window.localStorage.getItem('cookie-accepted');
        if (accepted)
        {
            this.setState({ collapsed: true, immediate: true });
        }
    }
    onClick = () =>
    {
        window.localStorage.setItem('cookie-accepted', 'true')
        this.setState({ collapsed: true });
    }
    render()
    {
        return <div className={cn('cookie-banner', {collapsed: this.state.collapsed, immediate: this.state.immediate})}>
            <div className="cookie-banner-content">
                <div className="cookie-banner-copy">
                    This website uses cookies so that we can provide you with the best user experience.
                    Without these cookies, the website will not allow you to register and submit your application.
                    For details on how to manage cookies please refer to the <Link to={'/privacy-policy'}>Privacy Policy</Link>.
                </div>
                <div className="cookie-banner-cta">
                    <button type="button" onClick={this.onClick} className="cookie-banner-cta-button">Okay</button>
                </div>
            </div>
        </div>;
    }
}

