import React, { useState, useEffect } from 'react';
import s from './Brief.module.scss';
import cn from 'classnames';
import Video from './../video/Video';
import VideoPosterImageWinners from '../../assets/2024/video_cover/winners_cover.png';


interface WinnersPropsType {
    inView: boolean
}

const Winners = ({ inView }: WinnersPropsType) => {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);
    const [videoPlaying, setVideoPlaying] = useState(false);
    return (
        <div className={s.pageCentered}>
            <div className={cn(s.pageFullWidth, s.pageBrief, s.pageWinners)}>
                <div className={s.briefContainer}>
                    <div className={s.contentWrapper}>
                        <div className={s.briefHeader}>
                            <div className={s.briefHeaderText}>THE WINNERS</div>
                        </div>
                        <div className={s.briefDescription}>
                            <p>
                            </p>
                        </div>

                    </div>
                    <div className={s.heroWrapper}>
                        <Video src='https://vimeo.com/966849185?share=copy' setVideoPlaying={setVideoPlaying} poster={VideoPosterImageWinners} />
                    </div>
                    <div className={s.winnersDescription}>
                        <p>Follow the winners experience at the Cannes Lions festival, including exclusive interviews with all the teams and highlights from the Future Lions award ceremony on <span className={s.nowrap}>Spotify Beach.</span></p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Winners;
