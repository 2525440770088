export function trackSponsors(sponsor) {
    _gaq?.push(['_trackEvent', 'Sponsors Icons', sponsor]);
}

export function attachGATracking(appName: string, gaid: string) {
    _gaq.push(["_setAccount", gaid]);
    _gaq.push(["_setDomainName", appName]);
    _gaq.push(["_trackPageview"]);
    (function () {
        const ga = document.createElement("script");
        ga.type = "text/javascript";
        ga.async = true;
        ga.src =
            ("https:" === document.location.protocol ?
                "https://ssl" :
                "http://www") + ".google-analytics.com/ga.js";
        const s = document.getElementsByTagName("script")[0];
        s?.parentNode?.insertBefore(ga, s);
    })();
}