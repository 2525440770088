import React, { ReactElement, ReactHTMLElement } from "react";
import "./Icon.scss"

type IconProps  = {
    icon?: ReactElement;
    onClick?: Function;
} & ReactHTMLElement<any>["props"];

export class Icon extends React.Component<IconProps, any> {
    render() {
        return <i className={ [this.props.className, "fl-icon"].filter(e => e).join(" ") } onClick={this.props.onClick}>
            { this.props.icon }
        </i>
    }
}