import React, { ReactHTMLElement, useEffect, useState } from 'react';
import ReactPlayer from 'react-player/vimeo';
import styles from './Video.module.scss';
import useGlobalContext from './../../hooks/useGlobalContext';

type VideoProps = {
    src: string;
    poster?: string;
    setVideoPlaying?: any;
} & ReactHTMLElement<HTMLDivElement>['props'];
const videoPages = [1, 3, 4];

const Video = ({ src, poster, setVideoPlaying }: VideoProps) => {
    const [playing, setPlaying] = useState(false);
    const [prevPage, setPrevPage] = useState(null);

    const { currPage } = useGlobalContext();

    useEffect(() => {
        if ((!videoPages.includes(currPage) || prevPage !== currPage) && playing) {
            setPlaying(false);
        }
        setPrevPage(currPage);
        setVideoPlaying(playing);
    }, [currPage, playing]);

    return (
        <div className={styles.container}>  
            <div className={styles.wrapper}>
                <ReactPlayer
                    controls
                    onPlay={() => setPlaying(true)}
                    onPause={() => setPlaying(false)}
                    className={styles.player}
                    url={src}
                    light={poster}
                    onClickPreview={() => setPlaying(true)}
                    playing={playing}
                    width='100%'
                    height='100%'
                    playIcon={<></>}
                />
            </div>
        </div>
    );
};

export default Video;
