import { siblings } from '../utils/dom';

export default function (Glide, Components, Events) {
  const Build = {
    /**
     * Init glide building. Adds classes, sets
     * dimensions and setups initial state.
     *
     * @return {Void}
     */
     yearToSlideMap: {
      2024: [0, 1, 2, 3],
      2023: [4, 5, 6, 7],
      2022: [8, 9, 10, 11],
      2021: [12, 13, 14, 15],
      2019: [16, 17, 18],
      2018: [19, 20, 21, 22, 23],
      2017: [24, 25, 26, 27, 28],
      2016: [29, 30, 31, 32, 33],
      2015: [34, 35, 36, 37, 38],
      2014: [39, 40, 41, 42, 43],
      2013: [44, 45, 46, 47, 48],
      2012: [49, 50, 51, 52, 53],
      2011: [54, 55, 56, 57],
      2010: [58, 59, 60, 61, 62],
      2009: [63, 64, 65, 66],
      2008: [67, 68, 69, 70, 71],
      2007: [72, 73, 74, 75, 76],
    },

    mount () {
      Events.emit('build.before')

      this.typeClass()
      this.activeClass()

      Events.emit('build.after')
    },

    /**
     * Adds `type` class to the glide element.
     *
     * @return {Void}
     */
    typeClass () {
      Components.Html.root.classList.add(Glide.settings.classes[Glide.settings.type])
    },

    /**
     * Sets active class to current slide.
     *
     * @return {Void}
     */
    activeClass () {
      let classes = Glide.settings.classes
      let slide = Components.Html.slides[Glide.index]
      let label = document.querySelectorAll('.alumni-nav .tab')
      if (slide) {
        slide.classList.add(classes.activeSlide)

        siblings(slide).forEach((sibling) => {
          sibling.classList.remove(classes.activeSlide)
        })
      }

      if (Glide.hasBeenActive) {
        label.forEach((item) => {
          if (this.yearToSlideMap[item.innerText].includes(Glide.index)) {
            item.classList.add('tab-active');
             siblings(item).forEach((sibling) => {
                sibling.classList.remove('tab-active');
            });
          }
        });
      }
    },

    /**
     * Removes HTML classes applied at building.
     *
     * @return {Void}
     */
    removeClasses () {
      let classes = Glide.settings.classes
      const label = document.querySelectorAll('.alumni-nav .tab')

      Components.Html.root.classList.remove(classes[Glide.settings.type])

      Components.Html.slides.forEach((sibling) => {
        sibling.classList.remove(classes.activeSlide)
      })
      
      label.forEach((item) => {
        item.classList.remove('tab-active');
      });
    }
  }

  /**
   * Clear building classes:
   * - on destroying to bring HTML to its initial state
   * - on updating to remove classes before remounting component
   */
  Events.on(['destroy', 'update'], () => {
    Build.removeClasses()
  })

  /**
   * Remount component:
   * - on resizing of the window to calculate new dimentions
   * - on updating settings via API
   */
  Events.on(['resize', 'update'], () => {
    Build.mount()
  })

  /**
   * Swap active class of current slide:
   * - after each move to the new index
   */
  Events.on('move.after', () => {
    Build.activeClass()
  })

  return Build
}
