import React, { ReactElement, ReactHTMLElement } from 'react';
import styles from './TermsAndConditions.module.scss';
import { Link } from 'react-router-dom';

type IconProps = {
    icon?: ReactElement;
} & ReactHTMLElement<any>['props'];

export class TermsAndConditions extends React.Component<IconProps, any> {
    render() {
        return (
            <div className={styles.termsAndConditions}>
                <div className={styles.contentWrapper}>
                    <h1 className={styles.title}>Terms and Conditions</h1>
                    <p>
                        Only young people and students can enter work that they have the right to submit. For the purpose of this competition a student is someone who is enrolled on an academic course and a young person is aged between 18 and 25 on 17 June 2024. All entrants should have less than 6 months industry experience.
                    </p>
                    <p>
                        Professionals and those working within the creative industries are not permitted to enter the competition.
                    </p>
                    <p>
                        Your work must be original; created by you; it must not contain any third-party materials, ideas or rights; and by entering you confirm your work is original and does not infringe anyone else’s intellectual property rights.
                    </p>
                    <p>
                        The winning entry will become the property of Spotify. AKQA will act as a liaison to ensure that Spotify contacts the winning students if they decide to research the winning idea. There will be no financial credit if the idea becomes commercialised, but it will be credited to you in name.
                    </p>
                    <p>
                        All winning work can be used by AKQA in the online Future Lions archives. The entrant also grants permission to AKQA and to show or allow others to show and publish the entries at such times as deemed appropriate.
                    </p>
                    <p>
                        AKQA and Spotify reserves the right to remove an entry from the competition for any reason, without notice or compensation.
                    </p>
                </div>

                <Link to={'/'}>
                    <div className={styles.header}>
                        <button className={styles.headerText}> Home</button>
                    </div>
                </Link>
            </div>
        );
    }
}
