import React, { ReactHTMLElement } from 'react';
import cn from 'classnames';

type TabsProps = {
    onClick;
    tabsDef;
    isDisabled;
} & ReactHTMLElement<HTMLDivElement>['props'];

export class Tabs extends React.Component<TabsProps, any> {
    constructor(props) {
        super(props);
        this.state = { selected: 0 };
    }
    genTabClick = (tab, idx) => {
        return () => {

            if(!this.props.isDisabled()) {
                this.setState({ selected: idx });
                document.querySelectorAll('.alumni-nav .tab').forEach((item) => {
                    if(!this.state.selected === idx) {
                    item.classList.remove('tab-active');
                    }
                  });
                this.props.onClick(tab);

            }
        };
    };
    render() {
        return (
            <div className='tabs'>
                {this.props.tabsDef.map((t, idx) => {
                    return (
                        <div
                            className={cn('tab', {
                                'tab-active': this.state.selected === idx
                            })}
                            key={idx}
                        >
                            <button
                                onClick={this.genTabClick(t, idx)}
                                className='tab-button'
                                type='button'
                            >
                                {t.label}
                            </button>
                            {/* <div
                                data-index={idx}
                                className={cn(
                                    'tab-underline',
                                    'img-underscore'
                                )}
                            /> */}
                        </div>
                    );
                })}
            </div>
        );
    }
}
