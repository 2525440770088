import React from 'react';
import { Link } from 'react-router-dom';
import style from './PrivacyPolicy.module.scss';

export class PrivacyPolicy extends React.Component {
    render() {
        return (
            <div className='privacy-policy'>
                <div className='privacy-policy-content-wrapper'>
                    {null && (
                        <>
                            <h1 className='privacy-title'>Privacy Policy</h1>
                            <h2 className='privacy-title-h2'>
                                Last updated March 13, 2018:
                            </h2>
                            <p>
                                Future Lions and its affiliates are committed to
                                protecting users’ privacy, and we want to keep
                                you informed of our privacy practices through
                                this web site. This privacy policy applies to
                                those Future Lions and affiliate web sites that
                                link to this notice. We encourage you to read
                                this policy to ensure you understand our privacy
                                practices.
                            </p>
                            <p>
                                The responsible use of cookies and web beacons
                                (aka: action tags or pixel gifs) plays an
                                important role in the services Future Lions and
                                its affiliates provide to applicants. Our
                                services allow our clients to better serve you.
                                Future Lions and its affiliates may use cookie
                                and web beacon technology for planning, serving,
                                analysing, and managing online campaigns.
                            </p>
                            <p>
                                For more information about the use of cookies,
                                web beacons, and how you can opt out of Google
                                DoubleClick cookies, please review the Google
                                DoubleClick privacy
                                policy,http://www.google.com/intl/en/privacy/ads/privacy-policy.html.
                                In addition, depending on your web browser, you
                                may be able to change how your computer treats
                                cookies so that cookies are not used or saved.
                                Please check with your browser provider for more
                                information on managing cookies. For more
                                information, please see the following resources:
                            </p>
                            <ul>
                                <li>
                                    http://www.networkadvertising.org/choices/
                                </li>
                                <li>http://www.youronlinechoices.com/</li>
                                <li>
                                    Google DoubleClick Opt Out Page:
                                    https://support.google.com/ads/answer/2662922?hl=en
                                </li>
                                <li>
                                    Google Preferences
                                    Page:https://www.google.com/ads/preferences/?hl=en
                                </li>
                            </ul>
                            <h2 className='privacy-title-h2'>
                                Future Lions and affiliate web sites:
                            </h2>
                            <p>
                                The Future Lions and affiliate web sites provide
                                users with information about the competition, as
                                well as company contact information. We do not
                                collect personal data unless you provide it to
                                us for items such as a competition registration.
                                We will express our need for the personal data
                                at the point of collection and will not collect
                                more personal data than is needed to execute
                                your request.
                            </p>
                            <p>
                                Personal data is any data that can be used to
                                identify an individual, and may include, but is
                                not limited to, name, email address, postal or
                                other physical address, telephone number and
                                other data required to provide a service,
                                deliver a product, or carry out a transaction
                                you have requested.
                            </p>
                            <p>
                                We will only use your personal data in the way
                                we specified when it was collected. We will not
                                subsequently change the way your personal data
                                is used without your consent.
                            </p>
                            <p>
                                We may respond to subpoenas, court orders, or
                                legal process by disclosing your personal data
                                and other related information, if necessary. We
                                also may disclose your personal data to
                                establish or exercise our legal rights or defend
                                against legal claims.
                            </p>

                            <h2 className='privacy-title-h2'>
                                Policy updates or changes:
                            </h2>
                        </>
                    )}

                    <div className='content'>
                        {' '}
                        <h1 id='privacy-policy'>Privacy Policy</h1>{' '}
                        <p>
                            This Privacy Policy was published in April 2018.
                            This website is operated by AKQA (“we” or “us”). We
                            respect the privacy of users of our website and
                            recognise that when you choose to provide us with
                            information about yourself, you trust us to act in a
                            responsible manner with that information. This
                            privacy policy contains important information about
                            how we use your personal information. If you just
                            want to know about how we use cookies, please see
                            the Cookies section below.
                        </p>{' '}
                        <h3 id='what-information-do-we-collect'>
                            What information do we collect?
                        </h3>{' '}
                        <p>
                            At times we may request that you voluntarily supply
                            us with personal information. Generally this
                            information is requested when you want us to provide
                            you with information. We may gather the following
                            information about you when you use this website:
                        </p>{' '}
                        <ul>
                            {' '}
                            <li>Name</li> <li>Email address</li>{' '}
                            <li>Telephone number</li>{' '}
                            <li>
                                IP address (a unique identifier for your
                                computer or other device)
                            </li>{' '}
                            <li>Mobile device ID</li>{' '}
                            <li>
                                Information provided by you in relation to
                                submitting a job application to us (this may
                                include sensitive personal information e.g.
                                ethnic origin)
                            </li>{' '}
                        </ul>{' '}
                        <h3 id='how-do-we-use-it'>How do we use it?</h3>{' '}
                        <p>
                            We will use your personal information in the
                            following ways. We are also required by law to state
                            a “legal basis for processing”, i.e. to tell you on
                            what grounds we are allowed to use your information,
                            and this is also set out below:
                        </p>{' '}
                        <h3 id='we-will-use-your-personal-information-to'>
                            We will use your personal information to:
                        </h3>{' '}
                        <ul>
                            {' '}
                            <li>
                                Provide you with information that you have
                                requested e.g. a newsletter, bulletins.
                            </li>{' '}
                            <li>
                                Respond to specific queries you may raise
                                regarding AKQA and its operating companies.
                            </li>{' '}
                            <li>
                                To deliver the best online experience by
                                understanding how you use our website to provide
                                you with better ways of accessing information{' '}
                            </li>{' '}
                            <li>Process and consider your job application.</li>{' '}
                        </ul>{' '}
                        <h3 id='our-legal-basis-for-processing'>
                            Our legal basis for processing:
                        </h3>{' '}
                        <ul>
                            {' '}
                            <li>
                                Consent - we only use your personal information
                                for this purpose if you have asked us to do so.
                                You can withdraw your consent at any time.
                            </li>{' '}
                            <li>
                                Our legitimate interests – we use your personal
                                information to help us to deliver the best
                                online experience to you and other website
                                users.
                            </li>{' '}
                            <li>
                                Our legitimate interests – we use your personal
                                information to assess your job application, and
                                to keep you updated throughout the application
                                process.
                            </li>{' '}
                        </ul>{' '}
                        <p>
                            For how we use your information that is collected
                            using cookies and similar technologies please see
                            the “Cookies” section below.
                        </p>{' '}
                        <h3 id='do-we-pass-your-information-to-third-parties'>
                            Do we pass your information to third parties?
                        </h3>{' '}
                        <p>
                            We may send your personal information to other group
                            companies, affiliates and third parties to help us
                            process your personal information for the purposes
                            set out in this policy. Further details of our group
                            companies can be found{' '}
                            <a
                                href='https://www.wpp.com/Contacts#tab-companies'
                                title='here'
                            >
                                here
                            </a>
                            . We may disclose your personal information if we or
                            any of our assets are the subject of a sale or
                            similar corporate transaction. We will ensure that
                            the third parties who receive your personal
                            information are required to keep it confidential. We
                            may disclose personal information to third parties
                            when we reasonably believe we are required by law,
                            and in order to investigate, prevent, or take action
                            regarding suspected or actual unlawful or otherwise
                            prohibited activities, including, but not limited
                            to, fraud.
                        </p>{' '}
                        <h3 id='how-do-we-use-your-information-for-marketing'>
                            How do we use your information for marketing?
                        </h3>{' '}
                        <p>
                            We will only provide information to you that you
                            have asked for via the website.
                        </p>{' '}
                        <h3 id='where-do-we-send-your-information'>
                            Where do we send your information?
                        </h3>{' '}
                        <p>
                            We are a global company and therefore we may
                            transfer your personal information to countries
                            around the world including the US and other
                            countries outside Europe. We will, where the country
                            to which your data is transferred has not been found
                            to provide an adequate level of protection, put in
                            place appropriate safeguards (we use standard
                            contractual clauses) to ensure your information is
                            protected.{' '}
                        </p>{' '}
                        <h3 id='how-long-do-we-keep-your-information'>
                            How long do we keep your information?
                        </h3>{' '}
                        <p>
                            We will keep your information for a period of 24
                            months.{' '}
                        </p>{' '}
                        <h3 id='cookies'>Cookies</h3>{' '}
                        <h5 id='what-are-cookies'>What are cookies?</h5>{' '}
                        <p>
                            We may use information obtained from cookies or
                            similar technology. Cookies are text files
                            containing small amounts of information which we
                            download onto your computer or device when you visit
                            our website. Therefore, when we refer to “you” in
                            this section we mean your computer. We can recognise
                            these cookies on subsequent visits and they allow us
                            to remember you. Cookies come in many forms. We have
                            set out below the main types and categories of
                            cookies that are used. This section refers to all
                            types and categories of cookies and not just those
                            we use on our website. First and third-party cookies
                            - whether a cookie is first-party or third-party
                            refers to the domain placing the cookie. First-party
                            cookies are those set by a website that is being
                            visited by the user, the website displayed in the
                            URL window e.g.{' '}
                            <a href='https://www.akqa.com/'>
                                https://www.akqa.com/
                            </a>
                            . Third-Party cookies are cookies that are set by a
                            domain other than that of the website being visited
                            by the user. If a user visits a website and another
                            entity sets a cookie through that website this would
                            be a third-party cookie. Persistent cookies – these
                            cookies remain on a user’s device for the period of
                            time specified in the cookie. They are activated
                            each time that the user visits the website that
                            created that particular cookie. Session cookie –
                            these cookies allow website operators to link the
                            actions of a user during a browser session. A
                            browser session starts when a user opens the browser
                            window and finishes when they close the browser
                            window. Session cookies are created temporarily.
                            Once you close the browser, all session cookies are
                            deleted.
                        </p>{' '}
                        <h5 id='what-do-we-use-cookies-for'>
                            What do we use cookies for?
                        </h5>{' '}
                        <p>
                            Cookies fall into one or more of the categories set
                            out below. This website uses cookies that fall into
                            all the categories. Also we use data from targeting
                            cookies placed on your computer by third parties
                            when you have visited other websites.
                        </p>{' '}
                        <ol>
                            {' '}
                            <li>
                                <p>
                                    Strictly necessary cookies – These cookies
                                    enable services you have specifically asked
                                    for. These cookies are essential in order to
                                    enable you to move around the website and
                                    use its features, such as accessing secure
                                    areas of the website. Without these cookies
                                    certain services you have asked for cannot
                                    be provided. e.g. the ASP.NET cookie.
                                </p>{' '}
                            </li>{' '}
                            <li>
                                <p>
                                    Performance cookies – These cookies collect
                                    information on the pages visited. These
                                    cookies collect information about how users
                                    use a website, for instance which pages
                                    users go to most often, and if they get
                                    error messages from web pages. These cookies
                                    don’t collect information that identifies a
                                    visitor. All information these cookies
                                    collect is aggregated and therefore
                                    anonymous. They are only used to improve how
                                    the website works.
                                </p>{' '}
                            </li>{' '}
                            <li>
                                <p>
                                    Functionality cookies – These cookies
                                    remember choices you make to improve your
                                    experience. These cookies allow the website
                                    to remember choices you make and provide
                                    enhanced, more personal features. For
                                    instance, e.g. the AddThis cookie that is
                                    used in AKQA’s corporate reports. The
                                    AddThis cookie provides ‘share this page’
                                    functionality, where a user can email a link
                                    from a report to their friends. They may
                                    also be used to help provide services you
                                    have asked for such as watching a video or
                                    commenting on a blog. The information these
                                    cookies collect may be anonymised and they
                                    cannot track your browsing activity to other
                                    websites.
                                </p>{' '}
                            </li>{' '}
                            <li>
                                <p>
                                    Targeting cookies or advertising cookies –
                                    these cookies collect information about your
                                    browsing habits in order to make advertising
                                    relevant to you and your interests. These
                                    cookies are used to deliver adverts more
                                    relevant to you and your interests. They are
                                    also used to limit the number of times you
                                    see an advertisement as well as help measure
                                    the effectiveness of the advertising
                                    campaign. They are usually placed by
                                    advertising networks with the website
                                    operator’s permission. They remember that
                                    you have visited a website and this
                                    information is shared with other
                                    organisation such as advertisers. Quite
                                    often targeting or advertising cookies will
                                    be linked to site functionality provided by
                                    the other organisation.
                                </p>{' '}
                            </li>{' '}
                        </ol>{' '}
                        <p>
                            If you want to delete any cookies that may already
                            be on your computer or device, please refer to the
                            instructions for your file management software to
                            locate the file or directory that stores cookies. If
                            you want to stop cookies being stored on your
                            computer in future, please refer to your browser
                            manufacturer’s instructions by clicking “Help” in
                            your browser menu.{' '}
                        </p>{' '}
                        <p>
                            Further information on cookies is available at{' '}
                            <a href='http://www.allaboutcookies.org'>
                                www.allaboutcookies.org
                            </a>
                            . By deleting our cookies or disabling future
                            cookies you may not be able to access certain areas
                            or features of our website.
                        </p>{' '}
                        <h3 id='third-party-websites'>Third Party Websites</h3>{' '}
                        <p>
                            Our website links to third party sites which we do
                            not operate or endorse. These websites may use
                            cookies and collect your personal information in
                            accordance with their own privacy policies. This
                            privacy policy does not apply to third party
                            websites and we are not responsible for third party
                            websites.
                        </p>{' '}
                        <h3 id='how-do-we-protect-your-information'>
                            How do we protect your information?
                        </h3>{' '}
                        <p>
                            We take appropriate measures to ensure that your
                            personal information disclosed to us is kept secure,
                            accurate and up to date and kept only for so long as
                            is necessary for the purposes for which it is used.
                        </p>{' '}
                        <h3 id='under-13-year-olds'>Under 13 year olds</h3>{' '}
                        <p>
                            This web site is not intended or designed to attract
                            children under the age of 13. We do not knowingly
                            collect personal information from or about any
                            person under the age of 13. If you are under 13
                            years old and wish to ask a question or use this
                            site in any way which requires you to submit your
                            personal information, please get your parent or
                            guardian to do so on your behalf.
                        </p>{' '}
                        <h3 id='your-rights'>Your rights</h3>{' '}
                        <p>You are entitled to ask: </p>{' '}
                        <ol>
                            {' '}
                            <li>
                                for a copy of the personal information we hold
                                about you, and details about how we are
                                processing your personal information;{' '}
                            </li>{' '}
                            <li>
                                to have any inaccuracies in your personal
                                information corrected;{' '}
                            </li>{' '}
                            <li>
                                if we are processing your personal information
                                by automated means and on the basis of your
                                consent (see “How do we use it?”, above), for us
                                to provide your personal information to you in a
                                structured, commonly-used and machine-readable
                                format. You can also ask us to provide your
                                personal information directly to a third party
                                in this format, and, if technically feasible, we
                                will do so; and
                            </li>{' '}
                            <li>
                                to have your personal information erased, or for
                                our use of it to be restricted (for example, if
                                your preferences change, or if you don’t want us
                                to send you the information you have requested).
                                Please contact us using the details set out
                                below if you would like to exercise any of these
                                rights. You also have the right to make a
                                complaint to the supervisory authority if you’re
                                not happy with how we’ve handled your personal
                                information. In the UK, the supervisory
                                authority is the Information Commissioner's
                                Office (
                                <a href='https://ico.org.uk/'>
                                    https://ico.org.uk/
                                </a>
                                ).
                            </li>{' '}
                        </ol>{' '}
                        <h3 id='how-to-contact-us'>How to contact us</h3>{' '}
                        <p>
                            If you wish to exercise any of your rights in
                            relation to your personal information or if you have
                            any queries about how we use your personal
                            information, please let us know by contacting us at
                            the following address:{' '}
                            <a href='mailto:yourdata@akqa.com'>
                                yourdata@akqa.com
                            </a>
                            .
                        </p>{' '}
                        <h3 id='changes-to-this-privacy-policy'>
                            Changes to this privacy policy
                        </h3>{' '}
                        <p>
                            We review this privacy policy on a regular basis to
                            ensure that it is up-to-date with our use of your
                            personal information, and compliant with applicable
                            data protection laws. We reserve the right, at our
                            discretion, to revise this privacy policy at any
                            time. The updated privacy policy will be posted on
                            our website. You are encouraged to review this
                            privacy policy from time to time.
                        </p>{' '}
                        <h3 id='data-protection-officer'>
                            Data Protection Officer
                        </h3>{' '}
                        <p>
                            Please contact the Data Protection Officer at{' '}
                            <a href='mailto:yourdata@akqa.com'>
                                yourdata@akqa.com
                            </a>{' '}
                        </p>{' '}
                    </div>
                </div>

                <Link to={'/'}>
                    <div className={style.header}>
                        <button className={style.headerText}> Home</button>
                    </div>
                </Link>
            </div>
        );
    }
}
